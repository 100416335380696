<template>
    <BT-Dialog
        buttonClass="mx-auto text-center"
        cancelText=""
        dense
        :hideButton="hideButton"
        :hideToggle="hideToggle"
        icon="mdi-cube-send"
        label="Adjust Stock"
        :loading="isLoading"
        okText=""
        :persistent="false"
        :showToggle="showToggle"
        small
        title="Adjust Stock"
        width="400">
        <template>
            <span v-if="msg != null">{{ msg }}</span>
            <BT-Number-Edit
                v-else
                v-model="quantity"
                label="Quantity" />
        </template>
        <template v-slot:actions>
            <v-btn @click="adjust('Add')">Add</v-btn>
            <v-btn @click="adjust('Remove')">Remove</v-btn>
            <v-btn @click="adjust('Hold')">Hold</v-btn>
            <v-btn @click="adjust('Waste')">Waste</v-btn>
        </template>
    </BT-Dialog>
</template>

<script>
export default {
    name: 'BT-Stock-Adjustment-Dialog',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            hideToggle: false,
            isLoading: false,
            msg: null,
            quantity: 0
        }
    },
    props: {
        hideButton: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        stockItem: {
            type: Object,
            default: null
        }
    },
    methods: {
        async adjust(adjustmentType) {
            if (this.stockItem == null) {
                return;
            }

            var adjustmentPOST = {
                productID: this.stockItem.productID,
                locationID: this.stockItem.locationID,
                batchID: this.stockItem.batchID,
                adjustmentType: adjustmentType,
                quantity: this.quantity
            }
            
            try {
                this.isLoading = true;

                await this.$BlitzIt.store.post('stock-adjustments', adjustmentPOST);
                if (adjustmentType == 'Add') {
                    this.stockItem.available += this.quantity;
                }
                else if (adjustmentType == 'Remove') {
                    this.stockItem.available -= this.quantity;
                }
                else if (adjustmentType == 'Hold') {
                    this.stockItem.available -= this.quantity;
                    this.stockItem.onHold += this.quantity;
                }
                else if (adjustmentType == 'Waste') {
                    this.stockItem.available -= this.quantity;
                    this.stockItem.wasted += this.quantity;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.isLoading = false;
                this.hideToggle = !this.hideToggle;
                this.quantity = 0;
            }
        }
    }
}
</script>